<template>
  <div class="pdf-container">
    <iframe :src="pdfUrl" class="pdf-iframe"></iframe>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      pdfUrl: ''
    }
  },
  methods: {
    ...mapActions('instrument', ['GET_PDF_CERTIFICATE_TRAINING'])
  },
  created() {
    const uuid = this.$route.params.uuid
    this.GET_PDF_CERTIFICATE_TRAINING(uuid).then(url => {
      this.pdfUrl = url
    })
  }
}
</script>

<style scoped>
.pdf-container {
  height: 100vh;
  width: 100%;
}
.pdf-iframe {
  height: 100%;
  width: 100%;
  border: none;
}
</style>
